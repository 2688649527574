import React from 'react'
import specialImg from '../../Images/special.jpg'
import line from '../../Images/line.png'
import './Specials.css'
import PageBanner from '../../Components/PageBanner/PageBanner'

function Specials() {
  return (
    <>
      <PageBanner pagename={"Specials"} />
      <div className="specialContainer">
        <div className="specialImg">
          <img src={specialImg} alt="" />
        </div>
        <div className="specialContent">
          <p>Our Special Food</p>
          <h1>Our Special</h1>
          <div className="specialInfoContainer">
            <div className="specialInfo">
              <h3>Stuffed Mushroom Caps</h3>
              <img src={line} alt="" />
              <p>$40</p>
            </div>
            <div>
              <p>Fiery Fusion of Flavors That Ignite the Palate</p>
            </div>
          </div>
          <div className="specialInfoContainer">
            <div className="specialInfo">
              <h3>Crispy Fried Dumplings</h3>
              <img src={line} alt="" />
              <p>$40</p>
            </div>
            <div>
              <p>Irresistible Crunchy Goodness to Delight Every Bite</p>
            </div>
          </div>
          <div className="specialInfoContainer">
            <div className="specialInfo">
              <h3>Creamy Paneer Tikka</h3>
              <img src={line} alt="" />
              <p>$40</p>
            </div>
            <div>
              <p>Divine Delight for Vegetarians, with Creamy Goodness</p>
            </div>
          </div>
          <div className="specialInfoContainer">
            <div className="specialInfo">
              <h3>Sizzling Garlic Noodles</h3>
              <img src={line} alt="" />
              <p>$40</p>
            </div>
            <div>
              <p>Satisfy Your Cravings with Our Aromatic Noodles</p>
            </div>
          </div>
        </div>
      </div>
      <div>
      </div>
    </>
  )
}

export default Specials
